import PropTypes from "prop-types";
import styled from "styled-components";
import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Nav from "../components/nav/nav";
import Context from "../state/context";
import { PlayAudioBtnDefault } from "../components/button/button";
import { Howl } from "howler";
import qr from './QR.png';
import logo from './ASCO_Conquer_Cancer_Foundation-White.png';

const ContentBody = styled.div`
  color: white;
  text-align: left;
  position: absolute;
  max-width: 850px;
  width: 100%;
  max-height: 85vh;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #4d4d4d;
  padding: 40px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  overflow: hidden;
`;

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const QRLink = styled.div`
    background-image: url(${qr});
    width: 300px;
    height: 300px;
    display: block;
    background-size: contain;
    display: block;
    margin: auto;
`;

const CClogo = styled.div`
    background-image: url(${logo});
    width: 246px;
    height: 37px;
    display: block;
    background-size: contain;
    display: block;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const audioClip = new Howl({
  src: ["./audio/Dr.ConorEvans.mp3"],
});

function Donate() {
  const { updateCurrentPage,updateToggleBackgroundAudio } = useContext(Context);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    updateCurrentPage(1);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioClip.pause();
    } else {
      audioClip.play();
      updateToggleBackgroundAudio(false);

    }
    setIsPlaying(!isPlaying);
  };

  return (
    <motion.div
      transition={{
        delay: 0.5,
        duration: 0.5,
        default: { ease: "easeInOut" },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ContentBody>
        <div className='container about'>
          <CloseBtn to='/home' onClick={handleClick}>
            <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M20.6663 12.2733L19.7263 11.3333L15.9997 15.06L12.273 11.3333L11.333 12.2733L15.0597 16L11.333 19.7266L12.273 20.6666L15.9997 16.94L19.7263 20.6666L20.6663 19.7266L16.9397 16L20.6663 12.2733Z'
                fill='white'
              />
              <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='white' />
            </svg>
          </CloseBtn>
          <div className='row my-7'>
            <div className='col'>
              <h1 className='mb-7'>Donate</h1>
              <p className='lead'>
                To support the work of ASCO, scan the QR code and donate to
              </p>
              <CClogo />
              <QRLink />
            </div>
          </div>
        </div>
      </ContentBody>
      <Nav />
    </motion.div>
  );
}
export default Donate;
